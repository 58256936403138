export const pptInitialValues = {
	name: '',
	type: '',
	state: '',
	address: '',
	country: '',
	street: '',
	city: '',
	zip: '',
	description: ''
}

export const pptTypes = ['Bungalow', 'Hostel', 'Duplex', 'Flat Apartment', 'Shops', 'Hall']