import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Outlet} from 'react-router';
import Nav from './components/navbar';
import {useEffect} from 'react'
import Tooltip from '@mui/material/Tooltip';

// Icon
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import GroupsIcon from '@mui/icons-material/Groups';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import {Link} from 'react-router-dom';
import VillaIcon from '@mui/icons-material/Villa';
import {Paper} from '@mui/material';
// import { ListItemButton } from '@mui/material';



const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 0.0625em)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 0.0625em)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function MiniDrawer() {
    useEffect(() => {
        document.title = 'Austel | My Dashboard'
    })


    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    // For selected icons
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <>
            <header className="block lg:hidden w-[100%] bg-white shadow-lg z-[1201] fixed tansition-all delay-150 ease px-5 py-3">
                <div className="flex justify-between w-[100%] items-center">
                    {/* Hello */}
                    <Link to='/dashboard/home' className='font-bold text-[#0FA958]'>Austel</Link>
                    <Nav />
                </div>
            </header>
            <Box className='hidden lg:flex' sx={{display: 'flex'}}>
                <CssBaseline />
                <div className="hidden lg:flex">
                    <AppBar position="fixed" open={open} style={{backgroundColor: 'white'}}>
                        <Toolbar className='flex bg-white'>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    backgroundColor: '#0FA958',
                                    color: 'white',
                                    ...(open && {display: 'none'}),
                                    '&:hover': {
                                        backgroundColor: '#0FA958',
                                        color: 'white'
                                    }
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className="flex justify-between w-[100%] items-center">
                                <Link to='/dashboard/home' className='font-bold text-[#0FA958]'>Austel</Link>
                                <Nav />
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>

                <Drawer variant="permanent" className='hidden lg:flex' open={open} style={{backgroundColor: '#EAFFF4'}}>
                    <Paper style={{backgroundColor: '#EAFFF4', height: '100vh'}}>
                        <DrawerHeader className='flex w-full justify-between' style={{width: '100% !important', justifyContent: 'space-between', WebkitJustifyContent: 'space-between !important'}}>
                            <div className='flex flex-col pt-5 py-3'>
                                <div className='relative cursor-pointer w-[50px] h-[50px]'>
                                    <img src="/profile-avatar.png" className='rounded-full' alt="" />
                                </div>
                                <p className='font-semibold text-[15px] text-[#0FA958] text-center'>Austel</p>
                            </div>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </DrawerHeader>
                        {/* List */}
                        <List className='flex flex-col gap-3'>
                            <ListItem button component={Link} to='/dashboard/home' selected={selectedIndex === 1} onClick={(e) => handleListItemClick(e, 1)} style={{backgroundColor: selectedIndex === 1 ? '#F1F1F1' : 'inherit', borderLeft: selectedIndex === 1 ? '7px solid #0FA958' : 'none'}}>
                                <Tooltip title='Overview' placement='right' arrow>
                                    <ListItemIcon>
                                        <DashboardTwoToneIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary='Dashboard' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>

                            <ListItem button component={Link} to='/dashboard/properties'
                                selected={selectedIndex === 3}
                                onClick={(e) => handleListItemClick(e, 3)}
                                style={{backgroundColor: selectedIndex === 3 ? '#F1F1F1' : 'inherit', borderLeft: selectedIndex === 3 ? '7px solid #0FA958' : 'none'}}
                            >
                                <Tooltip title="Properties" placement='right' arrow>
                                    <ListItemIcon>
                                        <VillaIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary='Properties' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>

                            <ListItem button component={Link} to='/dashboard/tenants'
                                selected={selectedIndex === 2}
                                onClick={(e) => handleListItemClick(e, 2)}
                                style={{backgroundColor: selectedIndex === 2 ? '#F1F1F1' : 'inherit', borderLeft: selectedIndex === 2 ? '7px solid #0FA958' : 'none'}}
                            >
                                <Tooltip title='Tenants' placement='right' arrow>
                                    <ListItemIcon>
                                        <GroupsIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary='Tenancy' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>

                            <ListItem button component={Link} to='/dashboard/payments'
                                selected={selectedIndex === 4}
                                onClick={(e) => handleListItemClick(e, 4)}
                                style={{backgroundColor: selectedIndex === 4 ? '#F1F1F1' : 'inherit', borderLeft: selectedIndex === 4 ? '7px solid #0FA958' : 'none'}}
                            >
                                <Tooltip title="Payment" placement='right' arrow>
                                    <ListItemIcon>
                                        <CreditCardIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary='Payment' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>

                            <ListItem button component={Link} to='/dashboard/message'
                                selected={selectedIndex === 5}
                                onClick={(e) => handleListItemClick(e, 5)}
                                style={{backgroundColor: selectedIndex === 5 ? '#F1F1F1' : 'inherit', borderLeft: selectedIndex === 5 ? '7px solid #0FA958' : 'none'}}
                            >
                                <Tooltip title="Messages" placement="right" arrow>
                                    <ListItemIcon>
                                        <ForumTwoToneIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary='Messages' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>

                            <ListItem button component={Link} to="/dashboard/letters"
                                selected={selectedIndex === 7}
                                onClick={(e) => handleListItemClick(e, 7)}
                            >
                                <Tooltip title="Letters" placement="right" arrow>
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary='Letters' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>

                            <ListItem button component={Link} to="/dashboard/logs"
                                selected={selectedIndex === 6}
                                onClick={(e) => handleListItemClick(e, 6)}
                            >
                                <Tooltip title="Log" placement="right" arrow>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary="Log" primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>

                            <ListItem button component={Link} to='/dashboard/settings'
                                selected={selectedIndex === 8}
                                onClick={(e) => handleListItemClick(e, 8)}
                            >
                                <Tooltip title="Settings" placement="right" arrow>
                                    <ListItemIcon>
                                        <SettingsTwoToneIcon />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary='Settings' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                            </ListItem>
                        </List>
                    </Paper>
                </Drawer>

                <div className='w-screen '>
                    <DrawerHeader />
                    <Typography paragraph className='p-0 w-full bg-[#F7F9F9]'>
                        <Outlet />
                    </Typography>
                </div>
            </Box >
        </>
    );
}
