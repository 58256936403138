import {Routes, Route, Navigate} from 'react-router';
import './App.css';
import Login from './agent/auth/login';
import Signup from './agent/auth/signup';
import Home from './agent/dash/pages/home';
import Error from './errorPage';
import Payment from './agent/dash/pages/payment';
import Properties from './agent/dash/pages/properties';
import DashboardGuard from './guards/DashboardGuard';
import PropertyDetails from './agent/dash/pages/property_details';
import UnitDetails from './agent/dash/pages/unit_details';




function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/Signup" />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signup' element={<Signup />} />

      <Route path='/dashboard' element={<DashboardGuard />} children={
        [
          <Route path='/dashboard/home' element={<Home />} />,
          <Route path='/dashboard/payments' element={<Payment />} />,
          <Route path='/dashboard/properties' element={<Properties />} />,
          <Route path='/dashboard/properties/:id' element={<PropertyDetails />} />,
          <Route path='/dashboard/unit_details' element={<UnitDetails />} />,
          // Error page
          <Route path='/dashboard/*' element={<Error />} />
        ]
      } />
      <Route path='*' element={<Error />} />
    </Routes>

  );
}

export default App;
