import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import Button from '@mui/material/Button';

// Icons
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
// import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
// import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';

export default function SimpleAccordion({setUnits, units, addUnit}) {

    const inputChange = (unitIndex, e) => {
        setUnits(
            _units => _units.map((unit, i) => unitIndex === i ?
                ({
                    ...unit, [e.name]: e.value,
                    rooms: (e.name === 'noOfRooms' ?
                        (new Array(+e.value).fill(0)).map((e, i) => ({tag: 'Room ' + (i + 1)}))
                        : unit.rooms
                    )
                }) : unit
            )
        )
    }

    const deleteUnit = (unitIndex) => {
        setUnits(_units => _units.filter((unit, i) => i !== unitIndex));
    }

    return (
        <div className='flex flex-col gap-3'>
            {units.map((unit, i) => <Accordion id='acc' style={{
                minWidth: '100%',
                marginBottom: 5,
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: '#E7F6EE'
                    }}
                    className='shadow'
                >
                    <Typography><h5 className='font-bold'>{unit.name}</h5></Typography> <small className='ml-2 mt-1 text-xs'>{unit.error ? (unit.error ?? 'Error') : (unit.loading ? 'Saving...' : (unit.saved ? 'Saved' : ''))}</small>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': {width: '100%'},
                        }}
                        noValidate
                        autoComplete="off"
                        className='mt-5'
                    >
                        <div className="w-100 gap-3 grid grid-cols-[60%,38%]">
                            <TextField id="standard-basic" label="Unit Name" variant="outlined" name='name' value={unit.name} onChange={(e) => inputChange(i, e.target)} placeholder='e.g Block A' disabled={unit.saved} />
                            <TextField id="standard-basic" label="No. of rooms" type='number' variant="outlined" name='noOfRooms' value={unit.noOfRooms} onChange={(e) => inputChange(i, e.target)} placeholder='e.g 10' disabled={unit.saved} />
                        </div>

                        <div className="w-100 flex mt-5">
                            {/* <button className='rounded-full px-5  text-white bg-austel-green hover:bg-[#2E8B57] text-sm'> < AddTwoToneIcon /> Add Payment
                            </button> */}
                            <div className="ml-auto">

                                {/* <IconButton aria-label="delete" size="large" >
                                    <BorderColorTwoToneIcon fontSize="inherit" className='text-blue-400' />
                                </IconButton> */}
                                <IconButton aria-label="delete" size="large" onClick={() => deleteUnit(i)} disabled={unit.saved}>
                                    <DeleteIcon fontSize="inherit" className='text-red-700' />
                                </IconButton>
                                {/* <IconButton aria-label="delete" size="large" onClick={() => saveUnit(i)} disabled={unitloading == i + 1}>
                                    {unitloading == i + 1 ? loader : <DescriptionTwoToneIcon fontSize="inherit" />}
                                </IconButton> */}
                            </div>
                        </div>
                    </Box>
                </AccordionDetails>
            </Accordion>)}
            <div className="w-100 flex mt-5 justify-end">
                <Button onClick={addUnit} variant='outlined' className='rounded-full px-6 py-3 text-white bg-austel-green hover:bg-[#2E8B57] ml-auto gap-10 flex'> Add Unit  <AccountBalanceTwoToneIcon />
                </Button>
            </div>
        </div>
    );
}