import React from 'react'
import TransitionalModal from "../components/transitional";
// Image
import SmallLoader from '../../widgets/SmallLoader';
import useAPI from '../../../hooks/useAPI';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

function UnitDetails() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div className="px-5 md:px-10 sm:px-3 flex flex-col gap-10 mt-5 md:mt-4 pt-3 bg-white mb-0">
            <div className='w-full text-[#6C6C6C] flex justify-between'>
                        <div className='flex gap-3'>
                            <h1 className='font-[600] text-[18px] md:text-[22px] cursor-pointer'>Properties</h1>
                            <span className='mt-3 text-[12px] md:text-[14px] cursor-pointer'> > Property Details</span>
                            <span className='mt-3 text-[12px] md:text-[14px] cursor-pointer'> > Unit Details</span>
                            <span className='mt-3 text-[12px] md:text-[14px]'> > Block 1</span>
                        </div>
                    </div>
            <div className='flex flex-col gap-4 pb-[2rem]'>
                    <div className='w-full text-[#6C6C6C] flex justify-between'>
                        <div className='flex gap-3'>
                            <h1 className='font-[600] text-[18px] md:text-[22px] cursor-pointer'>Unit Details</h1>
                        </div>
                        <div className='flex gap-2 items-center cursor-pointer'>
                            <AddIcon className='flex items-center cursor-pointer text-[#6C6C6C] -mt-1' sx={{ fontSize: '5' }} />
                            <span className='hidden md:flex text-[14px]'>Add Room</span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 w-full text-[#6C6C6C] text-[10px] md:text-[12px] font-[300] overflow-x-auto'>
                        {/* List Title */}
                        <div className='flex w-full justify-between font-bold px-4'>
                            <span className='w-[80px] md:w-[150px]'>Room No</span>
                            <span className='w-[100px] md:w-[150px]'>Occupant</span>
                            <span className='w-[100px] md:w-[150px] px-3'>No in a Room</span>
                            <span className='w-[100px] md:w-[150px]'> Rent Status</span>
                        </div>
                        {/* Data */}
                        <div className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                            <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[600]'>1</span>
                            <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[600]'>Tems Aya Blessing</span>
                            <span className='w-[100px] md:w-[150px] px-3'>2</span>
                            <span className='w-[100px] md:w-[150px] text-[#BD2424] font-bold'>Deliquent</span>
                        </div>

                        <div className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                            <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[600]'>2</span>
                            <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[600]'>Tems Aya Blessing</span>
                            <span className='w-[100px] md:w-[150px] px-3'>1</span>
                            <span className='w-[100px] md:w-[150px] text-[#0C9A00] font-bold'>Active</span>
                        </div>

                        <div className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                            <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[600]'>3</span>
                            <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[600]'></span>
                            <span className='w-[100px] md:w-[150px] px-3'>0</span>
                            <span className='w-[100px] md:w-[150px] text-[#6C6C6C] font-bold'>Inactive</span>
                        </div>

                        <div className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                            <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[600]'>4</span>
                            <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[600]'>Tems Aya Blessing</span>
                            <span className='w-[100px] md:w-[150px] px-3'>1</span>
                            <span className='w-[100px] md:w-[150px] text-[#0C9A00] font-bold'>Active</span>
                        </div>

                        <div className='bg-[#F1F1F1] flex justify-between px-4 py-3 text-[12px] md:text-[14px]'>
                            <span className='w-[80px] md:w-[150px] text-[#0FA958] font-[600]'>5</span>
                            <span className='w-[100px] md:w-[150px] text-[#0FA958] font-[600]'>Tems Aya Blessing</span>
                            <span className='w-[100px] md:w-[150px] px-3'>1</span>
                            <span className='w-[100px] md:w-[150px] text-[#0C9A00] font-bold'>Active</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
       )
    }
    
export default UnitDetails