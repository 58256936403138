import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import HorizontalLinearStepper from './linearStepper';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({getProperties}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <button className='px-4 py-2 hover:text-[#0FA958] hover:font-semibold flex items-center gap-1' onClick={handleClickOpen}> <AddHomeWorkIcon className='text-[#6C6C6C]' /> <p className='flex font-light text-[12px] md:text-[14px] items-center py-auto mt-2'>Create Property</p>
            </button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <HorizontalLinearStepper getProperties={getProperties} open={open} setOpen={setOpen} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}



