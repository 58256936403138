import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useFormik} from 'formik';
import UnitAccordion from '../components/unitAccordion';
import useAPI from '../../../hooks/useAPI';
import SmallLoader from '../../widgets/SmallLoader'
import {states} from '../../../services/base'
import {useNavigate} from 'react-router';
import {pptInitialValues, pptTypes} from '../../../services/utils';

const steps = ['Property Details', 'Unit Setup'];

export default function HorizontalLinearStepper({getProperties, open, setOpen}) {

    // Formik form
    const {values, handleChange, resetForm} = useFormik({initialValues: pptInitialValues})
    // End of formik

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const nextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }


    const handleNext = () => {
        // create property before going to units setup
        if (activeStep + 1 === 1) {
            createProperty();
            // setup units before showing `setup complete`
        } else if (activeStep + 1 === 2) {
            saveUnit();
        } else {
            nextStep()
        }
        // if(ac)
    };

    const {POST} = useAPI()
    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [property, setProperty] = React.useState(null)

    const createProperty = async () => {
        setLoading(true);
        setErrorText(null);
        const response = property
            ? await POST(`admin/properties/update`, {
                ...property, ...values,
                propertyId: property.id
            })
            : await POST(`admin/properties/new`, values);
        setLoading(false);

        if (response?.status) {
            !property && setProperty(response.data);
            nextStep()
        } else {
            setErrorText(response.message);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setOpen(false);
        setDone(false);
        navigate('/dashboard/properties');
    };

    // States in nigeria
    const statesItemList = states.map(stateItem => {
        return (
            <MenuItem value={stateItem}>{stateItem}</MenuItem>
        )
    })
    // End of states in  Nigeria

    // Property type
    const typeList = pptTypes.map(propertyItem => {
        return (
            <MenuItem value={propertyItem}>{propertyItem}</MenuItem>

        )
    })
    // End of property type  

    // handling multiple units
    const [units, setUnits] = React.useState([])

    React.useEffect(() => {
        if (activeStep === 1 && !units.length) addUnit();
        // eslint-disable-next-line
    }, [activeStep, units.length])

    const addUnit = () => {
        setUnits(e => [...e, {
            id: e.length + 1,
            name: 'New Unit ' + (e.length + 1),
            noOfRooms: 1, saved: false,
            rooms: [{tag: 'Room 1'}],
        }])
    }

    const saveUnit = async () => {
        // get unsaved units
        const filtered = units.filter(each => !each.saved)
        // loop through each unsaved units and save
        for (let i = 0;i < filtered.length;i++) {
            const eachUnit = filtered[i];
            updateUnit(eachUnit, {loading: true, error: false})
            const response = await POST('admin/properties/units/new', {...eachUnit, propertyId: property.id});
            if (response?.status) {
                updateUnit(eachUnit, {error: false, saved: true, loading: false})
            } else {
                updateUnit(eachUnit, {error: response?.message, loading: false})
            }
        }
    }

    const updateUnit = (unit, update) => {
        setUnits(e => e.map((eachUnit) => eachUnit.id === unit.id ? ({...eachUnit, ...update}) : eachUnit));
    }

    // when the dialog close
    React.useEffect(() => {
        if (!open && done) {
            setOpen(false);
            setDone(false);
            setActiveStep(0);
        }
        // refetch properties if the method is been passed down to this component
        getProperties && getProperties();
        // eslint-disable-next-line
    }, [open, done])


    // check if setup is completed
    React.useEffect(() => {
        // get unsaved units
        const unsavedUnits = units.filter(each => !each.saved)
        // unit setup step is skipped or (we have units, no unsaved unit and property has been set)
        if ((!unsavedUnits.length && property && units.length) || isStepSkipped(1)) {
            resetForm();
            setDone(true)
            setUnits([]);
            setActiveStep(2);
            setProperty(null);
            setSkipped(new Set())
        }
        // eslint-disable-next-line
    }, [units, property, activeStep])

    const loader = <SmallLoader />
    return (
        <Box sx={{width: '100%'}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //     labelProps.optional = (
                    //         <Typography variant="caption">Optional</Typography>
                    //     );
                    // }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{mt: 2, mb: 1}}>
                        <div className="max-w-md p-5 text-center">
                            Property setup complete. Click proceed to view your properties or click out to close this dialog.
                        </div>
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Box sx={{flex: '1 1 auto'}} />
                        <Button onClick={handleReset}>Proceed</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div sx={{mt: 2, mb: 1}}>
                        {
                            activeStep === 0 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'> Property Details</h1>
                                    <p className="text-[0.8em] mt-2">Enter your address information, then navigate to the property details section.</p>
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': {width: '100%'},
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className="w-100 gap-3 grid grid-cols-[58%,40%]">
                                        <TextField id="standard-basic" label="Property Name" variant="outlined" name='name' value={values.name} onChange={handleChange} />

                                        <Box sx={{minWidth: 120}}>
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='type'
                                                    value={values.type}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    {typeList}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>

                                    <div className="w-100 gap-3 gap-y-3 grid grid-cols-3 mt-5">
                                        <TextField id="standard-basic" label="Street" variant="outlined" name='street' value={values.street} onChange={handleChange} />

                                        <TextField id="standard-basic" label="City" variant="outlined" onChange={handleChange} value={values.city} name='city' />
                                        <TextField id="standard-basic" label="Zip" variant="outlined" type='number' value={values.zip} name='zip' onChange={handleChange} />

                                    </div>


                                    <div className="w-100 gap-3 grid grid-cols-[38%,60%] mt-5">
                                        <Box sx={{minWidth: 120}}>
                                            <FormControl fullWidth sx={{ color: '#6C6C6C' }}>
                                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={values.state}
                                                    name='state'
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }}
                                                >
                                                    {statesItemList}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <TextField id="standard-basic" label="Country" type='text' variant="outlined" name='country' value={values.country} onChange={handleChange} />
                                    </div>

                                    <div className="mt-5 grid">
                                        <TextField id="standard-basic" label="Street Address" type='text' variant="outlined" name='address' value={values.address} onChange={handleChange} />
                                    </div>

                                    {/* Comment */}
                                    <TextField id="standard-basic" label="Description" variant="standard" value={values.description} name='description' placeholder='More details about my property' onChange={handleChange} sx={{marginTop: '12px', marginBottom: '1em'}} />
                                </Box>
                            </div>
                        }
                        {
                            activeStep === 1 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'>Unit Setup</h1>
                                    <p className="text-[0.8em] mt-2">Enter the number of rooms for each of the following units. Click ADD UNIT to add more units to this property. <br /> NOTE: No unit will be created if this step is skipped</p>
                                </div>
                                <UnitAccordion setUnits={setUnits} units={units} addUnit={addUnit} />
                            </div>
                        }

                    </div>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: '1 1 auto'}} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext} disabled={loading} className='flex gap-1 items-center'>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}

                            {loading && loader}
                        </Button>
                    </Box>
                </React.Fragment>
            )
            }
        </Box >
    );
}
