import axios from 'axios'
import {useNavigate} from 'react-router';
import {BURL} from '../services/base'

const useAPI = () => {
	const navigate = useNavigate();

	axios.interceptors.response.use(res => res, (err) => {
		if (err?.response && err.response.status === 401) {
			localStorage.removeItem("token");
			navigate("/login");
		}
		return Promise.reject(err);
	});

	axios.interceptors.request.use((value) => {
		value.headers = {
			"Authorization": `Bearer ${localStorage.token}`
		};
		return value;
	})

	const POST = async (url, data) => {
		let response;
		await axios.post(`${BURL}${url}`, data).then(res => {
			response = res.data;
		}).catch(err => {
			let res = err?.response?.data;
			if (res) {
				response = res;
			} else {
				response = {
					message: "An error occured"
				}
			}
		})
		return response;
	}

	const GET = async (url) => {
		let response;
		await axios.get(`${BURL}${url}`).then(res => {
			response = res.data;
		}).catch(err => {
			let res = err?.response?.data;
			if (res) {
				response = res;
			} else {
				response = {
					message: "An error occured"
				}
			}
		})
		return response;
	}

	return {POST, GET}
}

export default useAPI