import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Navigate} from 'react-router';
import Dashboard from '../agent/dash/dashboard';
import useAPI from '../hooks/useAPI';

const DashboardGuard = () => {

	const loader = <div className='flex h-screen items-center justify-center'>
		Loading....
	</div>

	const tryAgain = useMemo(() =>
		<div className='flex h-screen items-center justify-center'>
			<button class='outline-none rounded-lg py-2 px-4 bg-light text-sm text-primary' onClick={() => window.location.reload()}>Try Again</button>
		</div>, []
	)

	const [currentView, setCurrentView] = useState(loader);

	const {GET} = useAPI();
	const getData = useCallback(async () => {
		const response = await GET('admin');
		if (response?.status) {
			setCurrentView(<Dashboard />)
		} else {
			setCurrentView(tryAgain);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tryAgain])

	useEffect(() => {
		if (!localStorage.token) {
			setCurrentView(<Navigate to="/login" />)
			return;
		}
		getData();
	}, [getData,])

	return currentView
}

export default DashboardGuard